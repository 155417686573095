export {
    renderWithCreateRange,
    linkAusBodyInHeadVerschieben
}

function renderWithCreateRange(container, html) {
    const range = document.createRange();
    range.setStart(container, 0);
    container.appendChild(
        range.createContextualFragment(html)
    );
}

/**
 * Der IE ignoriert in vielen Fällen die media-queries im CSS der Komponenten.
 * Um das Problem zu lösen, kopieren wir den <link> Tag aus der Komponente in den <head>
 * @param {object} customElement - das CustomElement, dessen <link> Knoten aus dem <body> in den <head> kopiert werden soll
 */
function linkAusBodyInHeadVerschieben(customElement) {
    const linkOrigin = customElement.querySelector('LINK');
    const link = document.createElement('LINK');
    link.rel = "stylesheet";
    link.href = linkOrigin.href;
    if (document.head.querySelector(`link[href="${linkOrigin.href}"]`) === null) document.head.appendChild(link);
    if (linkOrigin.parentNode) linkOrigin.parentNode.removeChild(linkOrigin);
}
