import { renderWithCreateRange, sleep } from "../../assets/js/utils";

class EmpfehlungenBootstrap extends HTMLElement {
    constructor() {
        super();
        this.removeAttribute("data-js");
        this.ladeindikator = this.querySelector(".ladeindikator");
        this.showLadeindikator();
        this.start();
    }

    get boxname() {
        return this.dataset.boxname;
    }

    get empfehlungenTyp() {
        return this.dataset.empfehlungenTyp;
    }

    disconnectedCallback() {
        this.disconnect();
    }

    start() {
        this.mutationObserver = this.initMutationObserver();
        this.intersectionObserver = this.initIntersectionObserver();
        this.addAnchorScrollListener();
    }

    addAnchorScrollListener() {
        // Wir hören auf zwei Events (start, finish) des Custom Elements <scroll-to-anchor> (Artikeldetailseite),
        // um plötzliches Stoppen der Seite beim Scrollen (Anker) zu vermeiden. Siehe dazu auch:
        // https://hd.thalia.de/jira/browse/SBE-8411
        // https://hd.thalia.de/jira/browse/SBE-7432
        document.addEventListener("sbe.anchorScroll.start", (event) => {
            this.disconnect();
        }, { once: true });
        document.addEventListener("sbe.anchorScroll.finish", (event) => {
            this.start();
        }, { once: true });
    }

    disconnect() {
        this.mutationObserver.disconnect();
        this.intersectionObserver.disconnect();
    }

    initMutationObserver() {
        const config = {
            attributes: true,
            childList: true,
            subtree: true
        }
        const observer = new MutationObserver((mutationsList, observer) => {
            mutationsList.forEach(mutation => this.mutationObserverHandler(mutation, observer));
        });
        observer.observe(this, config);
        return observer;
    }

    async mutationObserverHandler(mutation, observer) {
        const empfehlungenKomponente = mutation.target.querySelector("[component]");
        if (empfehlungenKomponente) {
            observer.disconnect();
            await sleep(100);
            this.bootstrapHoeheAktualisieren();
        }
    }

    initIntersectionObserver() {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => this.intersectionObserverHandler(entry, observer));
        });
        observer.observe(this.ladeindikator);
        return observer;
    }

    async intersectionObserverHandler(entry, observer) {
        const bereitsGeladen = !!this.querySelector("[component]");
        if (bereitsGeladen) {
            return;
        }
        if (entry.isIntersecting) {
            await this.initSlider();
            observer.disconnect();
        }
    }

    async initSlider() {
        try {
            const response = await fetch(this.dataset.empfehlungenUri);
            const html = await response.text();
            const responseIstLeer = html.length === 0;
            if (responseIstLeer) {
                this.parentNode.removeChild(this);
                return;
            }
            if (html) {
                renderWithCreateRange(this, html);
                if (this.boxname) {
                    this.bereichmodulAktivieren();
                }
                this.hideLadeindikator();
            }
        } catch (error) {
            console.error(error);
        }
    }

    bootstrapHoeheAktualisieren() {
        this.style.height = "auto";
        this.style.maxHeight = "none";
    }

    bereichmodulAktivieren() {
        const bereichmodule = this.querySelectorAll("[data-empfehlungen-box]");
        bereichmodule.forEach(modul => {
            const { empfehlungenBox } = modul.dataset;
            if (empfehlungenBox === this.boxname) {
                modul.style.display = "block";
                return;
            }
            modul.parentNode.removeChild(modul);
        })
    }

    showLadeindikator() {
        this.ladeindikator.removeAttribute("hidden");
    }
    
    hideLadeindikator() {
        this.ladeindikator.setAttribute("hidden", "");
    }
}

if (!customElements.get("empfehlungen-bootstrap")) {
    customElements.define("empfehlungen-bootstrap", EmpfehlungenBootstrap);
}
